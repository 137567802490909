import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import { AuthContext } from "./Base";
import { IEntity } from "./CRUDForm";
import { ListEditor } from "./ListEditor";
import MuiField from "./MuiField";
import { isAuthorized } from "./User.model";
import { Box, Button, Card, CardActions, CardContent, IconButton, ListItemIcon, ListItemText, MenuItem, TextField } from "@mui/material";
import { WithTranslation, withTranslation } from "react-i18next";
import { IUser } from "../modules/users/Users.model";
import React from "react";
import UserService, { ISharePrimitive, IUserService } from "../modules/users/Users.service";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import UserStrip from "../modules/profile/UserStrip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { CRUDButton } from "./CRUDButton";
import FileService from '../modules/files/Files.service';

interface IProps extends WithTranslation {
    onDone?(values: ISharePrimitive[]): void | Promise<void>,
    resourceId: number
}

interface IPropsO extends WithTranslation {
    onDone?(newOwnerId: number): void | Promise<void>,
    resourceIds: number[],
    oldOwnerId: number
}

interface IState {
    initShares: IShareFormData;
    userList?: IUser[];
}

interface IStateO {
    userList?: IUser[];
    newOwnerId: number;
}

interface IShareFormData {
    shares: ISharePrimitive[];
}

class SharePanelCl extends React.Component<IProps, IState, WithTranslation> {
    userService: IUserService;

    public constructor(props: IProps) {
        super(props);

        this.state = {
            initShares: { shares: [] },
            userList: undefined,
        };

        this.userService = UserService;
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    async componentDidMount() {
        const users = await (await this.userService.getAll()).data;
        const myShares = await (await this.userService.getShares(this.props.resourceId));
        this.setState({
            userList: users,
            initShares: { shares: myShares }
        });
    }

    doingSubmit = false;
    async onFormSubmit(values: IShareFormData) {
        if (this.doingSubmit) return;
        this.doingSubmit = true;
        await this.userService.setShares(this.props.resourceId, values.shares);
        await this.props.onDone?.(values.shares);
        this.doingSubmit = false;
    }

    render() {
        const t = this.props.t;
        const ns = 'module.users';

        const form = (props: FormikProps<IShareFormData>) => {
            return <Form>
                <FieldArray 
                    name="shares"
                    render={arrayHelpers => (
                        <Box>
                            {props.values.shares.map((value, index) => (<Card key={index} variant="outlined" sx={{ minWidth: 275, mb: '1rem', boxShadow: 2 }}>
                                <CardContent key={"x-" + index }>
                                        <Field name={`shares.${index}.userId`} component={MuiField} variant='select' labelKey="set-share.user" namespace="module.users" value={value.userId}>
                                        {this.state.userList!.map((user, ind) => <MenuItem key={ind} value={user.id!}><UserStrip user={ user } /></MenuItem>)}
                                        </Field>
                                        <Field name={`shares.${index}.mode`} component={MuiField} variant='select' labelKey="set-share.mode" namespace="module.users" value={value.mode}>
                                        <MenuItem value="READ_ONLY">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <VisibilityIcon sx={{ mr: '10px'}} />
                                                <div>
                                                    {t("share.mode.read-only", { ns: ns })}
                                                </div>
                                            </div>
                                        </MenuItem>
                                        <MenuItem value="READ_WRITE">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <EditIcon sx={{ mr: '10px' }} />
                                                <div>
                                                    {t("share.mode.read-write", { ns: ns })}
                                                </div>
                                            </div>
                                        </MenuItem>
                                        </Field>
                                    </CardContent>

                                <CardActions disableSpacing key={"y-" + index}>
                                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            ))}
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                                <Button variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => { arrayHelpers.push({ userId: 0, mode: "READ_ONLY" }); }}>
                                    {t(`${ns}.add-share`, { ns: ns })}
                                </Button>
                                <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
                                    {t("common.save", { ns: 'common' })}
                                </Button>
                            </Box>
                        </Box>
                    )} />
            </Form>
        }

        return this.state.userList !== undefined && this.state.userList !== undefined && (<Formik
            initialValues={this.state.initShares}
            onSubmit={this.onFormSubmit}
            component={form}
        >
        </Formik>);
    }
}

class ChangeOwnerPanelCl extends React.Component<IPropsO, IStateO, WithTranslation> {
    userService: IUserService;

    async componentDidMount() {
        const users = await (await this.userService.getAll()).data;
        this.setState({
            userList: users
        });
    }

    public constructor(props: IPropsO) {
        super(props);

        this.state = {
            userList: undefined,
            newOwnerId: this.props.oldOwnerId
        };

        this.userService = UserService;
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    doingSubmit = false;
    async onFormSubmit(items: number[], newOwnerId: number) {
        if (this.doingSubmit) return;
        this.doingSubmit = true;
        for (let i = 0; i < items.length; i++) {
            await FileService.changeOwner(items[i], newOwnerId);
        }
        await this.props.onDone?.(newOwnerId);
        this.doingSubmit = false;
    }

    render() {
        const t = this.props.t;
        const ns = 'module.files';
        return <><div key="chown-item" className="field-container">
			    <TextField
				    fullWidth select
				    value={this.state.newOwnerId} onChange={(e) => { this.setState({ newOwnerId: Number(e.target.value) }) }}
                    label={t("module.files.new-owner", {ns: ns})}>

				    {this.state.userList?.map(u => {
					    return (<MenuItem value={u.id!} key={u.id!}><UserStrip user={u} /></MenuItem>)
				    })}
			    </TextField>
		    </div>
		    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                <CRUDButton variant="contained" onClick={() => { this.onFormSubmit(this.props.resourceIds, this.state.newOwnerId); }} />
            </Box>
        </>
    }
}


/*const exp = {
    ChangeOwnerPanel: withTranslation()(ChangeOwnerPanel),
    SharePanel: withTranslation()(SharePanel) 
};

export default exp;*/

export const ChangeOwnerPanel = withTranslation()(ChangeOwnerPanelCl);
export const SharePanel = withTranslation()(SharePanelCl);