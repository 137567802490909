import React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import { createDefaultBoardInnerNode, createDefaultBoardLeafNode, IBoard, IBoardInnerNode, IBoardLeafNode, IBoardNode, isInner, isRoot, isLeaf, toInner, toLeaf, toRoot } from "./Boards.model";
import hoistStatics from 'hoist-non-react-statics';
import { Box, Button, Card, CardContent, Grid, IconButton } from '@mui/material';
import { Field, FieldArray, FieldArrayRenderProps, Form, Formik, FormikProps, getIn, setIn } from 'formik';
import { imageFilter } from '../files/Files.model';
import Boards from './Boards';
import { ColorPickerField, FilePickerField, MediaPicker, SwitchField } from '../../system/Formik.AdaptedFields';
import MuiField from '../../system/MuiField';
import MuiDateTimeField from '../../system/MuiDateTimeField';
/*import SaveIcon from '@mui/icons-material/Save';*/
import AddIcon from '@mui/icons-material/Add';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { isValidColorSimple } from '../../system/FieldValidators';
import { ListItem } from '../../system/ListEditor';
import EditIcon from '@mui/icons-material/Edit'
import { VisibilityOff } from '@mui/icons-material';


interface IChildContext {
    parentId: string,
    index: number,
    total: number,
    arrayHelpers: FieldArrayRenderProps
}

interface IState {
    editedBoard: string,
    collapsed: Map<number, boolean>,
    showTree: boolean,
}
interface IProps extends WithTranslation {
    entity: IBoard,
    role: 'create' | 'update',
    className?: string,
    readOnly: boolean,
    onSubmit: (values: IBoard) => Promise<any>
    onClose: () => void
}

class BoardChange extends React.Component<IProps, IState, WithTranslation>
{
    treeGridRef: React.RefObject<HTMLDivElement>;
    selectedBoardRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProps) {
        super(props);

        this.state = {
            editedBoard: '',
            collapsed: new Map<number, boolean>(),
            showTree: true,
        };

        this.identifyNode = this.identifyNode.bind(this);
        this.deidentifyNode = this.deidentifyNode.bind(this);
        this.editBoard = this.editBoard.bind(this);
        this.renderBoardTree = this.renderBoardTree.bind(this);
        this.renderEditor = this.renderEditor.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.addNode = this.addNode.bind(this);
        this.removeNode = this.removeNode.bind(this);
        this.getPropertyPath = this.getPropertyPath.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.correctNodeTypes = this.correctNodeTypes.bind(this);
        this.expandNodeTypes = this.expandNodeTypes.bind(this);
        this.setCollapsed = this.setCollapsed.bind(this);

        this.treeGridRef = React.createRef<HTMLDivElement>();
        this.selectedBoardRef = React.createRef<HTMLDivElement>();
    }

    // Naive function that generates temporary pseudounique keys for unidentified board nodes.
    // The keys are used for list rendering.
    identifyNode<T extends IBoardNode>(node: T): T {
        if (node.id !== null && node.id !== undefined)
            return node;
        
        node.id = Math.random();
        return node;
    }

    // Function that reverts temporary identification so that board nodes can be written to DB.
    deidentifyNode<T extends IBoardNode>(node: T): T {
        if (node.id === null || node.id === undefined || Number.isInteger(node.id))
            return node;
        
        node.id = undefined;
        return node;
    }

    validate(board: IBoard): any {
        let errors: any = {};
        const valueEmpty = this.props.t('validation.value-empty');

        let e = isValidColorSimple(board.color);
        if (e) errors = { ...errors, color: e };

        let unprocessed = [];
        for (let i = 0; i < board.children.length; ++i)
            unprocessed.push({ parent: board, index: i, propertyPath: `children.${i}`});

        while (unprocessed.length > 0) {
            const {parent, index, propertyPath}: {parent: IBoardInnerNode, index: number, propertyPath: string} = unprocessed.splice(0, 1)[0];
            let node: (IBoardInnerNode | IBoardLeafNode) = parent.children[index];

            if (isInner(node) && node.children.length > 0) {
                for (let i = 0; i < node.children.length; ++i)
                    unprocessed.push({ parent: node, index: i, propertyPath: `${propertyPath}.children.${i}` });
            
            } else if (isLeaf(node)) {
                if (!node.title) errors = setIn(errors, `${propertyPath}.title`, valueEmpty);
                if (!node.url) errors = setIn(errors, `${propertyPath}.url`, valueEmpty);
            }
        }
        return errors;
    }

    async onSubmit(values: IBoard) {
        const board = this.correctNodeTypes(values);
        await this.props.onSubmit(board);
    }

    expandNodeTypes(board: IBoard): IBoard {
        let unprocessed: {parent: IBoardInnerNode, index: number}[] = [];
        for (let i = 0; i < board.children.length; ++i)
            unprocessed.push({ parent: board, index: i});

        while (unprocessed.length > 0) {
            const {parent, index} = unprocessed.splice(0, 1)[0];
            let node: (IBoardInnerNode | IBoardLeafNode) = this.identifyNode(parent.children[index]);

            if (isInner(node)) {
                Object.assign(node, { ...createDefaultBoardLeafNode(), ...node });
                parent.children[index] = node;

                for (let i = 0; i < node.children.length; ++i)
                    unprocessed.push({ parent: node, index: i });
            } else {
                Object.assign(node, { ...createDefaultBoardInnerNode(), ...node });
                parent.children[index] = node;
            }
        }
        return this.identifyNode(board);
    }

    correctNodeTypes(board: IBoard): IBoard {
        let unprocessed = [];
        for (let i = 0; i < board.children.length; ++i)
            unprocessed.push({ parent: board, index: i});

        while (unprocessed.length > 0) {
            const {parent, index} = unprocessed.splice(0, 1)[0];
            let node: (IBoardInnerNode | IBoardLeafNode) = this.deidentifyNode(parent.children[index]);

            if (isInner(node) && node.children.length > 0) {
                node = toInner(node);
                parent.children[index] = node;

                for (let i = 0; i < node.children.length; ++i)
                    unprocessed.push({ parent: node, index: i });
            } else {
                parent.children[index] = toLeaf(node);
            }
        }
        return this.deidentifyNode(toRoot(board));
    }

    getPropertyPath(boardNamePath: string): string {
        return boardNamePath === '' ? '' : `${boardNamePath}.`;
    }

    editBoard(boardNamePath: string, callback?: () => void) {
        this.setState(() => ({
            editedBoard: boardNamePath,
        }), () => {
            this.selectedBoardRef.current?.scrollIntoView({ block: 'nearest'});
            if (callback) callback();
        });
    }

    addNode(childCount: number, boardNamePath: string, arrayHelpers: FieldArrayRenderProps, toBeginning: boolean = false) {
        const propertyPath = this.getPropertyPath(boardNamePath);

        const node = {...createDefaultBoardInnerNode(), ...createDefaultBoardLeafNode()};
        if (toBeginning)
            arrayHelpers.insert(0, this.identifyNode(node));
        else arrayHelpers.push(this.identifyNode(node));
        
        this.editBoard(`${propertyPath}children.${childCount}`);
    }
    
    moveNode(arrayHelpers: FieldArrayRenderProps, index: number, newIndex: number, boardNamePath: string) {
        arrayHelpers.move(index, newIndex);
        this.editBoard(boardNamePath);
    }

    removeNode(arrayHelpers: FieldArrayRenderProps, index: number, boardNamePath: string) {
        const parent = boardNamePath.substring(0, boardNamePath.lastIndexOf('.'));

        if (this.state.editedBoard.startsWith(boardNamePath) || this.state.editedBoard.startsWith(parent)) // the edited node might be removed or it's relative position might change
            this.editBoard('', () => arrayHelpers.remove(index));
        else
            arrayHelpers.remove(index);
    }

    setCollapsed(id: number, collapsed: boolean) {
        const copy = new Map<number, boolean>(this.state.collapsed.entries());
        copy.set(id, collapsed);
        this.setState({ collapsed: copy });
    }

    renderBoardTree(board: IBoardNode, boardNamePath: string, childContext?: IChildContext): JSX.Element {
        const selected = this.state.editedBoard === boardNamePath;
        const propertyPath = this.getPropertyPath(boardNamePath);
        const childless = isInner(board) && board.children.length === 0;
        const ns = Boards.getLocale();
        const cc = childContext;
        const touched = getIn(cc?.arrayHelpers.form.touched, boardNamePath);
        const error = getIn(cc?.arrayHelpers.form.errors, boardNamePath);

        return <Box className={`branch${childless ? ' childless' : ''}`} key={board.id}>
            {!childless && <input type="checkbox" checked={this.state.collapsed.has(board.id!) && this.state.collapsed.get(board.id!)} onChange={(e) => this.setCollapsed(board.id!, e.target.checked)} />}
                <ListItem
                    ref={selected ? this.selectedBoardRef : null}
                    item={board}
                    index={cc?.index ?? 0}
                    id={String(board.id)}
                    acceptType={cc?.parentId}
                    draggable={!this.props.readOnly}
                    name={(board) => board.title ? board.title : this.props.t(`${ns}.untitled`, {ns: ns})}
                    selected={selected} touched={touched} error={error}
                    selectItem={() => this.editBoard(boardNamePath)}
                    moveItem={(index, newIndex) => cc && this.moveNode(cc?.arrayHelpers, index, newIndex, boardNamePath)}
                    removeItem={(index) => cc && this.removeNode(cc?.arrayHelpers, index, boardNamePath)}
                    additionalActions={<>
                        {isInner(board) && <FieldArray name={`${propertyPath}children`} render={arrayHelpers =>
                        <IconButton onClick={(e) => { e.stopPropagation(); this.addNode(board.children.length, boardNamePath, arrayHelpers); return false; }}>
                            <AddIcon fontSize='small' />
                        </IconButton>} />}
                    </>}
                    dragBegin={(item, _) => this.setCollapsed(Number(item.id), true)}
                />
            {isInner(board) && <FieldArray name={`${propertyPath}children`}
                render={arrayHelpers => {
                    return (
                    <>
                        {board.children.map((child, i) => 
                            this.renderBoardTree(child, `${propertyPath}children.${i}`, { parentId: String(board.id), index: i, total: board.children.length, arrayHelpers })
                        )}
                    </>
                )}} />}
        </Box>;
    }

    renderEditor(formikProps: FormikProps<IBoard>): JSX.Element | null {
        const ns = Boards.getLocale();
        const propertyPath = this.getPropertyPath(this.state.editedBoard);
        const node: IBoardInnerNode = getIn(formikProps.values, this.state.editedBoard);

        console.assert(node !== undefined && node !== null);
        if (!node)
            return null; // shouldn't happen, but return rather than cause a crash

        return <Card /*key={this.state.editedBoard}*/ variant="outlined" sx={{ minWidth: 275, maxWidth: 400, mb: '1rem', boxShadow: 2, flexShrink: 0 }}>
            <CardContent>
                <Field disabled={this.props.readOnly} name={`${propertyPath}title`} labelKey='title' namespace={ns} component={MuiField} />
                <Field disabled={this.props.readOnly} name={`${propertyPath}image`} labelKey='image' namespace={ns} filter={imageFilter} filePicker={MediaPicker} component={FilePickerField} />
            
                {isRoot(node) && <Field disabled={this.props.readOnly} name={`${propertyPath}color`} labelKey='color' namespace={ns} component={ColorPickerField} />}
                {!isRoot(node) && node.children.length === 0 && <>
                    <Field disabled={this.props.readOnly} name={`${propertyPath}url`} labelKey='web-url' namespace={ns} component={MuiField} />
                    <Field disabled={this.props.readOnly} name={`${propertyPath}notifyUntil`} labelKey='notify-until' namespace={ns} component={MuiDateTimeField} />
                </>}
                <Field disabled={this.props.readOnly} name={`${propertyPath}showTitle`} labelKey='show-title' namespace={ns} component={SwitchField} type='checkbox' />
            </CardContent>
        </Card>;
    }

    renderForm(formikProps: FormikProps<IBoard>) {
        const overflowAuto = { height: { md: '100%' }, overflow: 'auto', maxWidth: { md: '40vw' } };
        const formStyle = { height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' };
        const button = { width: { xs: '100%', md: 'auto' }, alignSelf: 'center' };
        const t = this.props.t;
        const ns = Boards.getLocale();

        // @ts-ignore // there seems to be a bug with CSS properties in Form[style] attribute
        return <Form className='modal-form' style={formStyle}>
            <Grid container columnSpacing={{ xs: 0, md: 2 }} rowSpacing={2}
                sx={{ flexGrow: 1, overflowX: 'hidden', overflowY: { xs: 'scroll', md: 'hidden'}, width: { xs: '100%', md: 'max-content' } }}>
                
                <Grid item xs={12} sx={{ display: { md: 'none' }}}>
                    <Button variant='outlined' startIcon={<AccountTreeIcon />} onClick={() => this.setState({ showTree: !this.state.showTree })}>
                        {this.state.showTree
                        ? t(`${ns}.collapse-tree`, {ns})
                        : t(`${ns}.expand-tree`, {ns})}
                    </Button>
                </Grid>
                {this.state.showTree && <Grid item xs={12} md='auto' ref={this.treeGridRef} sx={{ ...overflowAuto, width: { md: 'min(400px, 50vw)' } }}
                    className={'tree' + (this.props.readOnly? ' modal-disable': '')}>
                    {this.renderBoardTree(formikProps.values, '')}
                </Grid>}
                <Grid item xs={12} md='auto' sx={{...overflowAuto, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    {this.renderEditor(formikProps)}
                </Grid>
            </Grid>
            {!this.props.readOnly &&
                <Button className="crud-button" sx={button} variant='contained' type="submit" startIcon={<EditIcon />}>
                    {t("crud.update", { ns: "common" })}
                </Button>
            }
            {this.props.readOnly &&
                <Button className="crud-button" sx={button} variant='outlined' type="button" onClick={this.props.onClose} startIcon={<VisibilityOff />}>
                    {t("crud.close", { ns: "common" })}
                </Button>
            }
        </Form>;
    }

    render() {
        return <Formik style={{ height: '100%', overflow: 'hidden' }} initialValues={this.expandNodeTypes(this.props.entity)}
            validate={this.validate} onSubmit={this.onSubmit} component={this.renderForm} />;
    }
}

export default hoistStatics(withTranslation()(BoardChange), BoardChange);