import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import { BE_ROOT } from "../../system/Communicator";
import FileService from "./Files.service";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckIcon from '@mui/icons-material/Check';
import DescriptionIcon from '@mui/icons-material/Description';
import SaveCloseButton from "../../system/SaveCloseButton";

import Editor from "react-simple-code-editor";
// @ts-ignore
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-ini";
import "prismjs/components/prism-uri";
import "prismjs/themes/prism.css";
import "./file-editor.css";

interface IState {
	content : string,
	isLoading : boolean,
	isSaving : boolean
	recentlySaved : boolean
}

interface IProps extends WithTranslation {
	directory : string
	path : string
	rows? : number
	highlightLanguage?: string
	readonly?: boolean
	forceEmbeded?: boolean
	onSubmit?: () => void
}

class FileEditor extends React.Component<IProps, IState, WithTranslation> {

	timeoutRecentlySaved : any;

	constructor(props : IProps)
	{
		super(props);
		this.state = {
			content : "",
			isLoading : true,
			isSaving : false,
			recentlySaved: false,
		};
	}

	async loadFileContent() {
		const fileExists = await FileService.existsInDirectory(this.props.directory, this.props.path.substring(1));
		let content = '';

		if (fileExists) {
			// We don't want to use tha API_URL here because we are not really using the API itself,
			// just an nginx endpoint for the file. So no /api/v1 prefix here.
			var c = await FileService.getBlob(BE_ROOT + "/" + this.props.directory + this.props.path);
			content = await c.data.text();
		}
		this.setState({ content : content, isLoading : false });
	}
	
	async markRecentlySaved() {
		this.setState({ isSaving : false, recentlySaved : true });
		this.timeoutRecentlySaved = window.setTimeout(()=>{
			this.setState({ recentlySaved : false });
			this.timeoutRecentlySaved = null;
		}, 2000);
	}
	
	async saveFileContent() {
		this.cleanRecentlySavedInterval();
		this.setState({ isSaving : true }, async ()=>{
			await FileService.setContent(this.props.directory, this.props.path, this.state.content);
			this.markRecentlySaved();
		})
	}

	async submitFileContent() {
		this.setState({ isSaving : true }, async ()=>{
			await FileService.setContent(this.props.directory, this.props.path, this.state.content);
			this.setState({ isSaving: false });
			if (this.props.onSubmit)
				this.props.onSubmit();
		})
	}
	
	cleanRecentlySavedInterval()
	{
		if (this.timeoutRecentlySaved) {
			window.clearInterval(this.timeoutRecentlySaved);
			this.timeoutRecentlySaved = null;
		}
	}

	componentDidMount() {
		this.loadFileContent();
	}
	
	componentWillUnmount() {
		this.cleanRecentlySavedInterval();
	}

	getTranslatedRoot(root: string, t: any): string {
		var key = "module.media.root-folder." + root;
		var tr = t(key, { ns: "module.media" });
		if (tr && tr !== key)
			return tr;
		else
			return root;
	}
		
	render() {
		const t = this.props.t;
	
		const hightlightWithLineNumbers = (input:any, language:any) =>
			highlight(input, language)
			.split("\n")
			.map((line:any, i:any) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
			.join("\n");

		// If we have onSubmit prop, it means the editor is in modal window.
		let saveButton = <></>;
		if (this.props.onSubmit && !this.props.forceEmbeded) {
			saveButton = <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
				<SaveCloseButton onClick={() => this.submitFileContent()} disabled={this.props.readonly} />
			</Box>
		} else {
			saveButton = <Box className='file-editor-buttons'><Button disabled={this.props.readonly || this.state.isSaving} color="primary" variant="contained" aria-label="save file" startIcon={
				this.state.isSaving ? <span style={{ zoom: 0.5 }}><CircularProgress /></span> :
					this.state.recentlySaved ? <CheckIcon /> : <SaveIcon />} onClick={() => {
						this.saveFileContent();
						if (this.props.onSubmit)
							this.props.onSubmit();
					}}>
				{t('common.save')}
			</Button>
			<Button disabled={this.state.isSaving} color="secondary" variant="outlined" aria-label="reload file" startIcon={
					<RestoreIcon />} onClick={() => this.setState({ isLoading: true }, this.loadFileContent )}>
				{t('common.reload')}
			</Button>
			</Box>
		}
	
		return <Box className="file-editor-wrapper">
			<div className="file-editor-title"><DescriptionIcon /> <span>{this.getTranslatedRoot(this.props.directory, this.props.t) + this.props.path}</span></div>
			{ this.state.isLoading &&
				<CircularProgress />
			}
			
			{ !this.state.isLoading && 
			<div className="file-editor-area"><Editor disabled={this.props.readonly || this.state.isSaving} value={this.state.content} padding={10}
				highlight={code => hightlightWithLineNumbers(code, languages[this.props.highlightLanguage || "txt"])}
				className="editor"
				textareaId="codeArea"
				style={{
					fontFamily: '"Fira code", "Fira Mono", monospace',
					fontSize: 18,
					outline: 0,
					minHeight: "100%"
					}}
				onValueChange={ (e)=>this.setState({ content : e }) }/></div>
			}
			
			{!this.state.isLoading && saveButton }
		</Box>
	}
}

export default hoistStatics(withTranslation()(FileEditor), FileEditor)